export const PFP_DATA = [
  {
    category: 'Head',
    layerNo: 10,
    connectedLayerNo: -1,
    elements: [
      {
        name: 'Original',
        rarity: 'Common',
        filename: '10_0010s_0007_common_original.png',
        score: '1',
      },
      {
        name: 'Mild',
        rarity: 'Common',
        filename: '10_0010s_0006_common_mild.png',
        score: '1',
      },
      {
        name: 'Teriyaki',
        rarity: 'Common',
        filename: '10_0010s_0005_common_teriyaki.png',
        score: '1',
      },
      {
        name: 'Dill Pickle',
        rarity: 'Common',
        filename: '10_0010s_0004_common_dillpickle.png',
        score: '1',
      },
      {
        name: 'Monster Size',
        rarity: 'Common',
        filename: '10_0010s_0008_common_monstersize.png',
        score: '1',
      },
      {
        name: 'Nacho',
        rarity: 'Common',
        filename: '10_0010s_0003_common_nacho.png',
        score: '1',
      },
      {
        name: 'Hot AF',
        rarity: 'Common',
        filename: '10_0010s_0009_common_hotaf.png',
        score: '1',
      },
      {
        name: 'Savage',
        rarity: 'Common',
        filename: '10_0010s_0001_common_savage.png',
        score: '1',
      },
      {
        name: 'Savage Spicy',
        rarity: 'Common',
        filename: '10_0010s_0000_common_savagespicy.png',
        score: '1',
      },
      {
        name: 'Honey BBQ',
        rarity: 'Common',
        filename: '10_0010s_0002_common_honeybbq.png',
        score: '1',
      },
    ],
  },
  {
    category: 'Head Accessory',
    layerNo: 5,
    connectedLayerNo: -1,
    elements: [
      {
        name: 'None',
        rarity: 'Common',
        filename: 'empty',
        score: '1',
      },
      {
        name: 'Slim Jim Headband',
        rarity: 'Common',
        filename: 'headband',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '05_0005s_0000s_0003_common_headband.png',
          },
          {
            rarity: 'Epic',
            filename: '05_0005s_0000s_0001_epic_headband.png',
          },
          {
            rarity: 'Legendary',
            filename: '05_0005s_0000s_0000_legendary_headband.png',
          },
          {
            rarity: 'Rare',
            filename: '05_0005s_0000s_0002_rare_headband.png',
          },
        ],
      },
      {
        name: 'Savage Cowboy Hat',
        rarity: 'Legendary',
        filename: 'savagehat',
        score: '4',
        colors: [
          {
            rarity: 'Common',
            filename: '05_0005s_0004s_0003_common_savagehat.png',
          },
          {
            rarity: 'Epic',
            filename: '05_0005s_0004s_0001_epic_savagehat.png',
          },
          {
            rarity: 'Legendary',
            filename: '05_0005s_0004s_0000_legendary_savagehat.png',
          },
          {
            rarity: 'Rare',
            filename: '05_0005s_0004s_0002_rare_savagehat.png',
          },
        ],
      },
      {
        name: 'Meat Roses Crown',
        rarity: 'Common',
        filename: 'meatrosecrown',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '05_0005s_0007s_0003_common_meatrosecrown.png',
          },
          {
            rarity: 'Epic',
            filename: '05_0005s_0007s_0001_epic_meatrosecrown.png',
          },
          {
            rarity: 'Legendary',
            filename: '05_0005s_0007s_0000_legendary_meatrosecrown.png',
          },
          {
            rarity: 'Rare',
            filename: '05_0005s_0007s_0002_rare_meatrosecrown.png',
          },
        ],
      },
      {
        name: 'Dimension Viewing Glasses',
        rarity: 'Rare',
        filename: 'dimensiongoggles',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '05_0005s_0005s_0003_common_dimensiongoggles.png',
          },
          {
            rarity: 'Epic',
            filename: '05_0005s_0005s_0001_epic_dimensiongoggles.png',
          },
          {
            rarity: 'Legendary',
            filename: '05_0005s_0005s_0000_legendary_dimensiongoggles.png',
          },
          {
            rarity: 'Rare',
            filename: '05_0005s_0005s_0002_rare_dimensiongoggles.png',
          },
        ],
      },
      {
        name: 'Sci-Fi Helmet',
        rarity: 'Rare',
        filename: 'scifihelmet',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '05_0005s_0008s_0003_common_scifihelmet.png',
          },
          {
            rarity: 'Epic',
            filename: '05_0005s_0008s_0001_epic_scifihelmet.png',
          },
          {
            rarity: 'Legendary',
            filename: '05_0005s_0008s_0000_legendary_scifihelmet.png',
          },
          {
            rarity: 'Rare',
            filename: '05_0005s_0008s_0002_rare_scifihelmet.png',
          },
        ],
      },
      {
        name: 'Savage Sunglasses',
        rarity: 'Epic',
        filename: 'glasses',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '05_0005s_0006s_0003_common_glasses.png',
          },
          {
            rarity: 'Epic',
            filename: '05_0005s_0006s_0001_epic_glasses.png',
          },
          {
            rarity: 'Legendary',
            filename: '05_0005s_0006s_0000_legendary_glasses.png',
          },
          {
            rarity: 'Rare',
            filename: '05_0005s_0006s_0002_rare_glasses.png',
          },
        ],
      },
      {
        name: 'Long Bois Gang Cap',
        rarity: 'Common',
        filename: 'lbghat',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '05_0005s_0001s_0003_common_lbghat.png',
          },
          {
            rarity: 'Epic',
            filename: '05_0005s_0001s_0001_epic_lbghat.png',
          },
          {
            rarity: 'Legendary',
            filename: '05_0005s_0001s_0000_legendary_lbghat.png',
          },
          {
            rarity: 'Rare',
            filename: '05_0005s_0001s_0002_rare_lbghat.png',
          },
        ],
      },
      {
        name: 'Cowboy Hat And Handle Moustache',
        rarity: 'Epic',
        filename: 'cowboyhat',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '05_0005s_0003s_0003_common_cowboyhat.png',
          },
          {
            rarity: 'Epic',
            filename: '05_0005s_0003s_0001_epic_cowboyhat.png',
          },
          {
            rarity: 'Legendary',
            filename: '05_0005s_0003s_0000_legendary_cowboyhat.png',
          },
          {
            rarity: 'Rare',
            filename: '05_0005s_0003s_0002_rare_cowboyhat.png',
          },
        ],
      },
      {
        name: 'Top Hat',
        set: 'Tuxedo',
        rarity: 'Legendary',
        filename: 'tophat',
        score: '4',
        colors: [
          {
            rarity: 'Common',
            filename: '05_0005s_0002s_0003_common_tophat.png',
          },
          {
            rarity: 'Epic',
            filename: '05_0005s_0002s_0001_epic_tophat.png',
          },
          {
            rarity: 'Legendary',
            filename: '05_0005s_0002s_0000_legendary_tophat.png',
          },
          {
            rarity: 'Rare',
            filename: '05_0005s_0002s_0002_rare_tophat.png',
          },
        ],
      },
    ],
  },
  {
    category: 'Torso',
    layerNo: 6,
    connectedLayerNo: -1,
    elements: [
      {
        name: 'Slim Jim Bodysuit Torso',
        rarity: 'Common',
        filename: 'bodysuittorso',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '06_0006s_0000s_0003_common_bodysuittorso.png',
          },
          {
            rarity: 'Epic',
            filename: '06_0006s_0000s_0001_epic_bodysuittorso.png',
          },
          {
            rarity: 'Legendary',
            filename: '06_0006s_0000s_0000_legendary_bodysuittorso.png',
          },
          {
            rarity: 'Rare',
            filename: '06_0006s_0000s_0002_rare_bodysuittorso.png',
          },
        ],
      },
      {
        name: 'Fanny Pack',
        rarity: 'Common',
        filename: 'fannypack',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '06_0006s_0006s_0003_common_fannypack.png',
          },
          {
            rarity: 'Epic',
            filename: '06_0006s_0006s_0001_epic_fannypack.png',
          },
          {
            rarity: 'Legendary',
            filename: '06_0006s_0006s_0000_legendary_fannypack.png',
          },
          {
            rarity: 'Rare',
            filename: '06_0006s_0006s_0002_rare_fannypack.png',
          },
        ],
      },
      {
        name: 'LBG T-Shirt',
        rarity: 'Common',
        filename: 'lbgshirt',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '06_0006s_0010s_0003_common_lbgshirt.png',
          },
          {
            rarity: 'Epic',
            filename: '06_0006s_0010s_0001_epic_lbgshirt.png',
          },
          {
            rarity: 'Legendary',
            filename: '06_0006s_0010s_0000_legendary_lbgshirt.png',
          },
          {
            rarity: 'Rare',
            filename: '06_0006s_0010s_0002_rare_lbgshirt.png',
          },
        ],
      },
      {
        name: 'LBG Hoodie',
        rarity: 'Common',
        filename: 'lbghoodie',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '06_0006s_0008s_0003_common_lbghoodie.png',
          },
          {
            rarity: 'Epic',
            filename: '06_0006s_0008s_0001_epic_lbghoodie.png',
          },
          {
            rarity: 'Legendary',
            filename: '06_0006s_0008s_0000_legendary_lbghoodie.png',
          },
          {
            rarity: 'Rare',
            filename: '06_0006s_0008s_0002_rare_lbghoodie.png',
          },
        ],
      },
      {
        name: 'Lab Coat',
        set: 'Lab',
        rarity: 'Common',
        filename: 'labcoat',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '06_0006s_0002s_0003_common_labcoat.png',
          },
          {
            rarity: 'Epic',
            filename: '06_0006s_0002s_0001_epic_labcoat.png',
          },
          {
            rarity: 'Legendary',
            filename: '06_0006s_0002s_0000_legendary_labcoat.png',
          },
          {
            rarity: 'Rare',
            filename: '06_0006s_0002s_0002_rare_labcoat.png',
          },
        ],
      },
      {
        name: 'Basketball Jersey',
        rarity: 'Common',
        filename: 'basketballjersey',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '06_0006s_0009s_0003_common_basketballjersey.png',
          },
          {
            rarity: 'Epic',
            filename: '06_0006s_0009s_0001_epic_basketballjersey.png',
          },
          {
            rarity: 'Legendary',
            filename: '06_0006s_0009s_0000_legendary_basketballjersey.png',
          },
          {
            rarity: 'Rare',
            filename: '06_0006s_0009s_0002_rare_basketballjersey.png',
          },
        ],
      },
      {
        name: 'Cowboy Shirt',
        rarity: 'Common',
        filename: 'cowboytorso',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '06_0006s_0007s_0003_common_cowboytorso.png',
          },
          {
            rarity: 'Epic',
            filename: '06_0006s_0007s_0001_epic_cowboytorso.png',
          },
          {
            rarity: 'Legendary',
            filename: '06_0006s_0007s_0000_legendary_cowboytorso.png',
          },
          {
            rarity: 'Rare',
            filename: '06_0006s_0007s_0002_rare_cowboytorso.png',
          },
        ],
      },
      {
        name: 'Ballet Tutu',
        set: 'Ballet',
        rarity: 'Rare',
        filename: 'tutu',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '06_0006s_0012s_0003_common_tutu.png',
          },
          {
            rarity: 'Epic',
            filename: '06_0006s_0012s_0001_epic_tutu.png',
          },
          {
            rarity: 'Legendary',
            filename: '06_0006s_0012s_0000_legendary_tutu.png',
          },
          {
            rarity: 'Rare',
            filename: '06_0006s_0012s_0002_rare_tutu.png',
          },
        ],
      },
      {
        name: '"Eat Me" Slim Jim Overalls Top',
        set: 'Eat Me',
        rarity: 'Rare',
        filename: 'sjoverallstorso',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '06_0006s_0001s_0003_common_sjoverallstorso.png',
          },
          {
            rarity: 'Epic',
            filename: '06_0006s_0001s_0001_epic_sjoverallstorso.png',
          },
          {
            rarity: 'Legendary',
            filename: '06_0006s_0001s_0000_legendary_sjoverallstorso.png',
          },
          {
            rarity: 'Rare',
            filename: '06_0006s_0001s_0002_rare_sjoverallstorso.png',
          },
        ],
      },
      {
        name: 'Wrestling Singlet Torso',
        rarity: 'Epic',
        filename: 'wrestlingsinglet',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '06_0006s_0011s_0003_common_wrestlingsinglet.png',
          },
          {
            rarity: 'Epic',
            filename: '06_0006s_0011s_0001_epic_wrestlingsinglet.png',
          },
          {
            rarity: 'Legendary',
            filename: '06_0006s_0011s_0000_legendary_wrestlingsinglet.png',
          },
          {
            rarity: 'Rare',
            filename: '06_0006s_0011s_0002_rare_wrestlingsinglet.png',
          },
        ],
      },
      {
        name: 'Horck In A Baby Carrier',
        rarity: 'Epic',
        filename: 'horckcarrier',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '06_0006s_0004s_0003_common_horckcarrier.png',
          },
          {
            rarity: 'Epic',
            filename: '06_0006s_0004s_0001_epic_horckcarrier.png',
          },
          {
            rarity: 'Legendary',
            filename: '06_0006s_0004s_0000_legendary_horckcarrier.png',
          },
          {
            rarity: 'Rare',
            filename: '06_0006s_0004s_0002_rare_horckcarrier.png',
          },
        ],
      },
      {
        name: 'Slender James Tuxedo',
        set: 'Tuxedo',
        rarity: 'Legendary',
        filename: 'sjtuxedo',
        score: '4',
        colors: [
          {
            rarity: 'Common',
            filename: '06_0006s_0003s_0003_common_sjtuxedo.png',
          },
          {
            rarity: 'Epic',
            filename: '06_0006s_0003s_0001_epic_sjtuxedo.png',
          },
          {
            rarity: 'Legendary',
            filename: '06_0006s_0003s_0000_legendary_sjtuxedo.png',
          },
          {
            rarity: 'Rare',
            filename: '06_0006s_0003s_0002_rare_sjtuxedo.png',
          },
        ],
      },
      {
        name: 'Gold CEO Torso',
        set: 'CEO',
        rarity: 'Legendary',
        filename: '06_0006s_0005s_0000_legendary_ceogoldtorso.png',
        score: '15',
      },
    ],
  },
  {
    category: 'Back Accessory',
    layerNo: 11,
    connectedLayerNo: -1,
    elements: [
      {
        name: 'None',
        rarity: 'Common',
        filename: 'empty',
        score: '1',
      },
      {
        name: 'SJ Backpack',
        rarity: 'Common',
        filename: 'backpack',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '11_0011s_0000s_0003_common_backpack.png',
          },
          {
            rarity: 'Epic',
            filename: '11_0011s_0000s_0001_epic_backpack.png',
          },
          {
            rarity: 'Legendary',
            filename: '11_0011s_0000s_0000_legendary_backpack.png',
          },
          {
            rarity: 'Rare',
            filename: '11_0011s_0000s_0002_rare_backpack.png',
          },
        ],
      },
      {
        name: 'Pogo Stick',
        rarity: 'Common',
        filename: 'pogostick',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '11_0011s_0001s_0003_common_pogostick.png',
          },
          {
            rarity: 'Epic',
            filename: '11_0011s_0001s_0001_epic_pogostick.png',
          },
          {
            rarity: 'Legendary',
            filename: '11_0011s_0001s_0000_legendary_pogostick.png',
          },
          {
            rarity: 'Rare',
            filename: '11_0011s_0001s_0002_rare_pogostick.png',
          },
        ],
      },
      {
        name: 'Giant Slim Jim',
        rarity: 'Rare',
        filename: 'giantsj',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '11_0011s_0002s_0003_common_giantsj.png',
          },
          {
            rarity: 'Epic',
            filename: '11_0011s_0002s_0001_epic_giantsj.png',
          },
          {
            rarity: 'Legendary',
            filename: '11_0011s_0002s_0000_legendary_giantsj.png',
          },
          {
            rarity: 'Rare',
            filename: '11_0011s_0002s_0002_rare_giantsj.png',
          },
        ],
      },
      {
        name: 'Jet Pack',
        rarity: 'Epic',
        filename: 'jetpack',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '11_0011s_0003s_0003_common_jetpack.png',
          },
          {
            rarity: 'Epic',
            filename: '11_0011s_0003s_0001_epic_jetpack.png',
          },
          {
            rarity: 'Legendary',
            filename: '11_0011s_0003s_0000_legendary_jetpack.png',
          },
          {
            rarity: 'Rare',
            filename: '11_0011s_0003s_0002_rare_jetpack.png',
          },
        ],
      },
      {
        name: 'Long Boi Gang Rocket',
        rarity: 'Epic',
        filename: 'rocket',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '11_0011s_0005s_0003_common_rocket.png',
          },
          {
            rarity: 'Epic',
            filename: '11_0011s_0005s_0001_epic_rocket.png',
          },
          {
            rarity: 'Legendary',
            filename: '11_0011s_0005s_0000_legendary_rocket.png',
          },
          {
            rarity: 'Rare',
            filename: '11_0011s_0005s_0002_rare_rocket.png',
          },
        ],
      },
      {
        name: 'Hero Cape',
        rarity: 'Legendary',
        filename: 'cape',
        score: '4',
        colors: [
          {
            rarity: 'Common',
            filename: '11_0011s_0004s_0003_common_cape.png',
          },
          {
            rarity: 'Epic',
            filename: '11_0011s_0004s_0001_epic_cape.png',
          },
          {
            rarity: 'Legendary',
            filename: '11_0011s_0004s_0000_legendary_cape.png',
          },
          {
            rarity: 'Rare',
            filename: '11_0011s_0004s_0002_rare_cape.png',
          },
        ],
      },
      {
        name: 'Giant Gangimal',
        rarity: 'Legendary',
        filename: 'giantplushie',
        score: '4',
        colors: [
          {
            rarity: 'Common',
            filename: '11_0011s_0006s_0003_common_giantplushie.png',
          },
          {
            rarity: 'Epic',
            filename: '11_0011s_0006s_0001_epic_giantplushie.png',
          },
          {
            rarity: 'Legendary',
            filename: '11_0011s_0006s_0000_legendary_giantplushie.png',
          },
          {
            rarity: 'Rare',
            filename: '11_0011s_0006s_0002_rare_giantplushie.png',
          },
        ],
      },
    ],
  },
  {
    category: 'Arm Accessory',
    layerNo: 4,
    connectedLayerNo: 9,
    elements: [
      {
        name: 'None',
        rarity: 'Common',
        filename: 'empty',
        score: '1',
      },
      {
        name: 'Slim Jim Holder',
        rarity: 'Common',
        filename: 'sjholster',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '04_0004s_0000s_0003_common_sjholster.png',
            connected: '09_0009s_0007s_0003_common_sjholster.png',
          },
          {
            rarity: 'Epic',
            filename: '04_0004s_0000s_0001_epic_sjholster.png',
            connected: '09_0009s_0007s_0001_epic_sjholster.png',
          },
          {
            rarity: 'Legendary',
            filename: '04_0004s_0000s_0000_legendary_sjholster.png',
            connected: '09_0009s_0007s_0000_legendary_sjholster.png',
          },
          {
            rarity: 'Rare',
            filename: '04_0004s_0000s_0002_rare_sjholster.png',
            connected: '09_0009s_0007s_0002_rare_sjholster.png',
          },
        ],
      },
      {
        name: '69 Tattoo',
        rarity: 'Common',
        filename: '69tattoo',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '04_0004s_0004s_0003_common_69tattoo.png',
            connected: '09_0009s_0008s_0003_common_69tattoo.png',
          },
          {
            rarity: 'Epic',
            filename: '04_0004s_0004s_0001_epic_69tattoo.png',
            connected: '09_0009s_0008s_0001_epic_69tattoo.png',
          },
          {
            rarity: 'Legendary',
            filename: '04_0004s_0004s_0000_legendary_69tattoo.png',
            connected: '09_0009s_0008s_0000_legendary_69tattoo.png',
          },
          {
            rarity: 'Rare',
            filename: '04_0004s_0004s_0002_rare_69tattoo.png',
            connected: '09_0009s_0008s_0002_rare_69tattoo.png',
          },
        ],
      },
      {
        name: 'LBG Tattoo',
        rarity: 'Common',
        filename: 'lbgtattoo',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '04_0004s_0006s_0003_common_lbgtattoo.png',
            connected: '09_0009s_0009s_0003_common_lbgtattoo.png',
          },
          {
            rarity: 'Epic',
            filename: '04_0004s_0006s_0001_epic_lbgtattoo.png',
            connected: '09_0009s_0009s_0001_epic_lbgtattoo.png',
          },
          {
            rarity: 'Legendary',
            filename: '04_0004s_0006s_0000_legendary_lbgtattoo.png',
            connected: '09_0009s_0009s_0000_legendary_lbgtattoo.png',
          },
          {
            rarity: 'Rare',
            filename: '04_0004s_0006s_0002_rare_lbgtattoo.png',
            connected: '09_0009s_0009s_0002_rare_lbgtattoo.png',
          },
        ],
      },
      {
        name: 'Phone Holder',
        rarity: 'Common',
        filename: 'phoneholder',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '04_0004s_0008s_0003_common_phoneholder.png',
            connected: '09_0009s_0004s_0003_common_phoneholder.png',
          },
          {
            rarity: 'Epic',
            filename: '04_0004s_0008s_0001_epic_phoneholder.png',
            connected: '09_0009s_0004s_0001_epic_phoneholder.png',
          },
          {
            rarity: 'Legendary',
            filename: '04_0004s_0008s_0000_legendary_phoneholder.png',
            connected: '09_0009s_0004s_0000_legendary_phoneholder.png',
          },
          {
            rarity: 'Rare',
            filename: '04_0004s_0008s_0002_rare_phoneholder.png',
            connected: '09_0009s_0004s_0002_rare_phoneholder.png',
          },
        ],
      },
      {
        name: 'Cyborg Arm',
        set: 'Cyborg',
        rarity: 'Rare',
        filename: 'cyborgarm',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '04_0004s_0002s_0003_common_cyborgarm.png',
            connected: '09_0009s_0000s_0003_common_cyborgarm.png',
          },
          {
            rarity: 'Epic',
            filename: '04_0004s_0002s_0001_epic_cyborgarm.png',
            connected: '09_0009s_0000s_0001_epic_cyborgarm.png',
          },
          {
            rarity: 'Legendary',
            filename: '04_0004s_0002s_0000_legendary_cyborgarm.png',
            connected: '09_0009s_0000s_0000_legendary_cyborgarm.png',
          },
          {
            rarity: 'Rare',
            filename: '04_0004s_0002s_0002_rare_cyborgarm.png',
            connected: '09_0009s_0000s_0002_rare_cyborgarm.png',
          },
        ],
      },
      {
        name: 'Jewellery Arm',
        rarity: 'Rare',
        filename: 'jeweleryarm',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '04_0004s_0007s_0003_common_jeweleryarm.png',
          },
          {
            rarity: 'Epic',
            filename: '04_0004s_0007s_0001_epic_jeweleryarm.png',
          },
          {
            rarity: 'Legendary',
            filename: '04_0004s_0007s_0000_legendary_jeweleryarm.png',
          },
          {
            rarity: 'Rare',
            filename: '04_0004s_0007s_0002_rare_jeweleryarm.png',
          },
        ],
      },
      {
        name: 'SJ Scanner',
        rarity: 'Epic',
        filename: 'armscanner',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '04_0004s_0001s_0003_common_armscanner.png',
            connected: '09_0009s_0001s_0003_common_armscanner.png',
          },
          {
            rarity: 'Epic',
            filename: '04_0004s_0001s_0001_epic_armscanner.png',
            connected: '09_0009s_0001s_0001_epic_armscanner.png',
          },
          {
            rarity: 'Legendary',
            filename: '04_0004s_0001s_0000_legendary_armscanner.png',
            connected: '09_0009s_0001s_0000_legendary_armscanner.png',
          },
          {
            rarity: 'Rare',
            filename: '04_0004s_0001s_0002_rare_armscanner.png',
            connected: '09_0009s_0001s_0002_rare_armscanner.png',
          },
        ],
      },
      {
        name: 'Twin Head',
        rarity: 'Epic',
        filename: 'twinhead',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '04_0004s_0009s_0003_common_twinhead.png',
            connected: '09_0009s_0003s_0003_common_twinhead.png',
          },
          {
            rarity: 'Epic',
            filename: '04_0004s_0009s_0001_epic_twinhead.png',
            connected: '09_0009s_0003s_0001_epic_twinhead.png',
          },
          {
            rarity: 'Legendary',
            filename: '04_0004s_0009s_0000_legendary_twinhead.png',
            connected: '09_0009s_0003s_0000_legendary_twinhead.png',
          },
          {
            rarity: 'Rare',
            filename: '04_0004s_0009s_0002_rare_twinhead.png',
            connected: '09_0009s_0003s_0002_rare_twinhead.png',
          },
        ],
      },
      {
        name: 'Nice Cube Shoulder Pads',
        rarity: 'Legendary',
        filename: 'nicepad',
        score: '4',
        colors: [
          {
            rarity: 'Common',
            filename: '04_0004s_0003s_0003_common_nicepad.png',
            connected: '09_0009s_0005s_0003_common_nicepad.png',
          },
          {
            rarity: 'Epic',
            filename: '04_0004s_0003s_0001_epic_nicepad.png',
            connected: '09_0009s_0005s_0001_epic_nicepad.png',
          },
          {
            rarity: 'Legendary',
            filename: '04_0004s_0003s_0000_legendary_nicepad.png',
            connected: '09_0009s_0005s_0000_legendary_nicepad.png',
          },
          {
            rarity: 'Rare',
            filename: '04_0004s_0003s_0002_rare_nicepad.png',
            connected: '09_0009s_0005s_0002_rare_nicepad.png',
          },
        ],
      },
      {
        name: 'Shoulder Mounted Rockets',
        rarity: 'Legendary',
        filename: 'shoulderrocket',
        score: '4',
        colors: [
          {
            rarity: 'Common',
            filename: '04_0004s_0005s_0003_common_shoulderrocket.png',
            connected: '09_0009s_0006s_0003_common_shoulderrocket.png',
          },
          {
            rarity: 'Epic',
            filename: '04_0004s_0005s_0001_epic_shoulderrocket.png',
            connected: '09_0009s_0006s_0001_epic_shoulderrocket.png',
          },
          {
            rarity: 'Legendary',
            filename: '04_0004s_0005s_0000_legendary_shoulderrocket.png',
            connected: '09_0009s_0006s_0000_legendary_shoulderrocket.png',
          },
          {
            rarity: 'Rare',
            filename: '04_0004s_0005s_0002_rare_shoulderrocket.png',
            connected: '09_0009s_0006s_0002_rare_shoulderrocket.png',
          },
        ],
      },
    ],
  },
  {
    category: 'Hands',
    layerNo: 2,
    connectedLayerNo: -1,
    elements: [
      {
        name: 'None',
        rarity: 'Common',
        filename: 'empty',
        score: '1',
      },
      {
        name: 'Fingerless Gloves',
        rarity: 'Common',
        filename: 'fingerlessglover',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '02_0002s_0007s_0003_common_fingerlessglover.png',
          },
          {
            rarity: 'Epic',
            filename: '02_0002s_0007s_0001_epic_fingerlessglover.png',
          },
          {
            rarity: 'Legendary',
            filename: '02_0002s_0007s_0000_legendary_fingerlessglover.png',
          },
          {
            rarity: 'Rare',
            filename: '02_0002s_0007s_0002_rare_fingerlessglover.png',
          },
        ],
      },
      {
        name: 'Super Hero Gloves',
        rarity: 'Common',
        filename: 'herogloves',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '02_0002s_0005s_0003_common_herogloves.png',
          },
          {
            rarity: 'Epic',
            filename: '02_0002s_0005s_0001_epic_herogloves.png',
          },
          {
            rarity: 'Legendary',
            filename: '02_0002s_0005s_0000_legendary_herogloves.png',
          },
          {
            rarity: 'Rare',
            filename: '02_0002s_0005s_0002_rare_herogloves.png',
          },
        ],
      },
      {
        name: 'Cowboy Gloves',
        rarity: 'Rare',
        filename: 'cowboygloves',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '02_0002s_0000s_0003_common_cowboygloves.png',
          },
          {
            rarity: 'Epic',
            filename: '02_0002s_0000s_0001_epic_cowboygloves.png',
          },
          {
            rarity: 'Legendary',
            filename: '02_0002s_0000s_0000_legendary_cowboygloves.png',
          },
          {
            rarity: 'Rare',
            filename: '02_0002s_0000s_0002_rare_cowboygloves.png',
          },
        ],
      },
      {
        name: 'Cyborg Hands',
        set: 'Cyborg',
        rarity: 'Rare',
        filename: 'cyborghands',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '02_0002s_0004s_0003_common_cyborghands.png',
          },
          {
            rarity: 'Epic',
            filename: '02_0002s_0004s_0001_epic_cyborghands.png',
          },
          {
            rarity: 'Legendary',
            filename: '02_0002s_0004s_0000_legendary_cyborghands.png',
          },
          {
            rarity: 'Rare',
            filename: '02_0002s_0004s_0002_rare_cyborghands.png',
          },
        ],
      },
      {
        name: 'Black Latex Gloves',
        set: 'Lab',
        rarity: 'Epic',
        filename: 'latexgloves',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '02_0002s_0001s_0003_common_latexgloves.png',
          },
          {
            rarity: 'Epic',
            filename: '02_0002s_0001s_0001_epic_latexgloves.png',
          },
          {
            rarity: 'Legendary',
            filename: '02_0002s_0001s_0000_legendary_latexgloves.png',
          },
          {
            rarity: 'Rare',
            filename: '02_0002s_0001s_0002_rare_latexgloves.png',
          },
        ],
      },
      {
        name: 'Long Boi Hands',
        rarity: 'Epic',
        filename: 'lbghr',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '02_0002s_0006s_0003_common_lbgh.png',
          },
          {
            rarity: 'Epic',
            filename: '02_0002s_0006s_0001_epic_lbgh.png',
          },
          {
            rarity: 'Legendary',
            filename: '02_0002s_0006s_0000_legendary_lbgh.png',
          },
          {
            rarity: 'Rare',
            filename: '02_0002s_0006s_0002_rare_lbgh.png',
          },
        ],
      },
      {
        name: 'Meat Fingers',
        set: 'Meat',
        rarity: 'Legendary',
        filename: 'meatfingers',
        score: '4',
        colors: [
          {
            rarity: 'Common',
            filename: '02_0002s_0002s_0003_common_meatfingers.png',
          },
          {
            rarity: 'Epic',
            filename: '02_0002s_0002s_0001_epic_meatfingers.png',
          },
          {
            rarity: 'Legendary',
            filename: '02_0002s_0002s_0000_legendary_meatfingers.png',
          },
          {
            rarity: 'Rare',
            filename: '02_0002s_0002s_0002_rare_meatfingers.png',
          },
        ],
      },
      {
        name: 'Gold CEO',
        set: 'CEO',
        rarity: 'Legendary',
        filename: '02_0002s_0003s_0000_legendary_ceogoldhands.png',
        score: '15',
      },
    ],
  },
  {
    category: 'Left Hand Accessory',
    layerNo: 1,
    connectedLayerNo: -1,
    elements: [
      {
        name: 'None',
        rarity: 'Common',
        filename: 'empty',
        score: '1',
      },
      {
        name: 'SJ Pack (L)',
        rarity: 'Common',
        filename: 'sjpack',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '01_0001s_0005s_0003_common_sjpack.png',
          },
          {
            rarity: 'Epic',
            filename: '01_0001s_0005s_0001_epic_sjpack.png',
          },
          {
            rarity: 'Legendary',
            filename: '01_0001s_0005s_0000_legendary_sjpack.png',
          },
          {
            rarity: 'Rare',
            filename: '01_0001s_0005s_0002_rare_sjpack.png',
          },
        ],
      },
      {
        name: 'Meat Nunchucks',
        set: 'Meat',
        rarity: 'Common',
        filename: 'nunchucks',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '01_0001s_0007s_0003_common_nunchucks.png',
          },
          {
            rarity: 'Epic',
            filename: '01_0001s_0007s_0001_epic_nunchucks.png',
          },
          {
            rarity: 'Legendary',
            filename: '01_0001s_0007s_0000_legendary_nunchucks.png',
          },
          {
            rarity: 'Rare',
            filename: '01_0001s_0007s_0002_rare_nunchucks.png',
          },
        ],
      },
      {
        name: 'Erlenmeyer Flask',
        set: 'Lab',
        rarity: 'Common',
        filename: 'beaker',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '01_0001s_0003s_0003_common_beaker.png',
          },
          {
            rarity: 'Epic',
            filename: '01_0001s_0003s_0001_epic_beaker.png',
          },
          {
            rarity: 'Legendary',
            filename: '01_0001s_0003s_0000_legendary_beaker.png',
          },
          {
            rarity: 'Rare',
            filename: '01_0001s_0003s_0002_rare_beaker.png',
          },
        ],
      },
      {
        name: 'Cowboy Lasso',
        rarity: 'Rare',
        filename: 'cowboylasso',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '01_0001s_0000s_0003_common_cowboylasso.png',
          },
          {
            rarity: 'Epic',
            filename: '01_0001s_0000s_0001_epic_cowboylasso.png',
          },
          {
            rarity: 'Legendary',
            filename: '01_0001s_0000s_0000_legendary_cowboylasso.png',
          },
          {
            rarity: 'Rare',
            filename: '01_0001s_0000s_0002_rare_cowboylasso.png',
          },
        ],
      },
      {
        name: 'Clown Slim Jim Stick',
        rarity: 'Rare',
        filename: 'clownstick',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '01_0001s_0006s_0003_common_clownstick.png',
          },
          {
            rarity: 'Epic',
            filename: '01_0001s_0006s_0001_epic_clownstick.png',
          },
          {
            rarity: 'Legendary',
            filename: '01_0001s_0006s_0000_legendary_clownstick.png',
          },
          {
            rarity: 'Rare',
            filename: '01_0001s_0006s_0002_rare_clownstick.png',
          },
        ],
      },
      {
        name: 'SJ Boombox',
        rarity: 'Epic',
        filename: 'boombox',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '01_0001s_0008s_0003_common_boombox.png',
          },
          {
            rarity: 'Epic',
            filename: '01_0001s_0008s_0001_epic_boombox.png',
          },
          {
            rarity: 'Legendary',
            filename: '01_0001s_0008s_0000_legendary_boombox.png',
          },
          {
            rarity: 'Rare',
            filename: '01_0001s_0008s_0002_rare_boombox.png',
          },
        ],
      },
      {
        name: 'Crystal Scepter',
        rarity: 'Epic',
        filename: 'jewelscepter',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '01_0001s_0004s_0003_common_jewelscepter.png',
          },
          {
            rarity: 'Epic',
            filename: '01_0001s_0004s_0001_epic_jewelscepter.png',
          },
          {
            rarity: 'Legendary',
            filename: '01_0001s_0004s_0000_legendary_jewelscepter.png',
          },
          {
            rarity: 'Rare',
            filename: '01_0001s_0004s_0002_rare_jewelscepter.png',
          },
        ],
      },
      {
        name: 'Top Secret Meataverse Case',
        rarity: 'Legendary',
        filename: 'meataversesuitcase',
        score: '4',
        colors: [
          {
            rarity: 'Common',
            filename: '01_0001s_0002s_0003_common_meataversesuitcase.png',
          },
          {
            rarity: 'Epic',
            filename: '01_0001s_0002s_0001_epic_meataversesuitcase.png',
          },
          {
            rarity: 'Legendary',
            filename: '01_0001s_0002s_0000_legendary_meataversesuitcase.png',
          },
          {
            rarity: 'Rare',
            filename: '01_0001s_0002s_0002_rare_meataversesuitcase.png',
          },
        ],
      },
      {
        name: 'Sauce Pod Container',
        rarity: 'Legendary',
        filename: 'multisaucepack',
        score: '4',
        colors: [
          {
            rarity: 'Common',
            filename: '01_0001s_0001s_0003_common_multisaucepack.png',
          },
          {
            rarity: 'Epic',
            filename: '01_0001s_0001s_0001_epic_multisaucepack.png',
          },
          {
            rarity: 'Legendary',
            filename: '01_0001s_0001s_0000_legendary_multisaucepack.png',
          },
          {
            rarity: 'Rare',
            filename: '01_0001s_0001s_0002_rare_multisaucepack.png',
          },
        ],
      },
    ],
  },
  {
    category: 'Right Hand Accessory',
    layerNo: 3,
    connectedLayerNo: -1,
    elements: [
      {
        name: 'None',
        rarity: 'Common',
        filename: 'empty',
        score: '1',
      },
      {
        name: 'SJ Pack (R)',
        rarity: 'Common',
        filename: 'sjpack',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '03_0003s_0003s_0003_common_sjpack.png',
          },
          {
            rarity: 'Epic',
            filename: '03_0003s_0003s_0001_epic_sjpack.png',
          },
          {
            rarity: 'Legendary',
            filename: '03_0003s_0003s_0000_legendary_sjpack.png',
          },
          {
            rarity: 'Rare',
            filename: '03_0003s_0003s_0002_rare_sjpack.png',
          },
        ],
      },
      {
        name: 'Jewellery Case',
        rarity: 'Common',
        filename: 'jewlerybag',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '03_0003s_0001s_0003_common_jewelerybag.png',
          },
          {
            rarity: 'Epic',
            filename: '03_0003s_0001s_0001_epic_jewelerybag.png',
          },
          {
            rarity: 'Legendary',
            filename: '03_0003s_0001s_0000_legendary_jewelerybag.png',
          },
          {
            rarity: 'Rare',
            filename: '03_0003s_0001s_0002_rare_jewelerybag.png',
          },
        ],
      },
      {
        name: 'Floating Beaker',
        set: 'Lab',
        rarity: 'Common',
        filename: 'flask',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '03_0003s_0000s_0003_common_flask.png',
          },
          {
            rarity: 'Epic',
            filename: '03_0003s_0000s_0001_epic_flask.png',
          },
          {
            rarity: 'Legendary',
            filename: '03_0003s_0000s_0000_legendary_flask.png',
          },
          {
            rarity: 'Rare',
            filename: '03_0003s_0000s_0002_rare_flask.png',
          },
        ],
      },
      {
        name: 'Meat Sword',
        set: 'Meat',
        rarity: 'Rare',
        filename: 'saber',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '03_0003s_0006s_0003_common_saber.png',
          },
          {
            rarity: 'Epic',
            filename: '03_0003s_0006s_0001_epic_meatsaber.png',
          },
          {
            rarity: 'Legendary',
            filename: '03_0003s_0006s_0000_legendary_meatsaber.png',
          },
          {
            rarity: 'Rare',
            filename: '03_0003s_0006s_0002_rare_saber.png',
          },
        ],
      },
      {
        name: 'Rubber Duck',
        rarity: 'Rare',
        filename: 'rubberduck',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '03_0003s_0002s_0003_common_rubberduck.png',
          },
          {
            rarity: 'Epic',
            filename: '03_0003s_0002s_0001_epic_rubberduck.png',
          },
          {
            rarity: 'Legendary',
            filename: '03_0003s_0002s_0000_legendary_rubberduck.png',
          },
          {
            rarity: 'Rare',
            filename: '03_0003s_0002s_0002_rare_rubberduck.png',
          },
        ],
      },
      {
        name: 'Nice Cube',
        rarity: 'Epic',
        filename: 'nicecube',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '03_0003s_0007s_0003_common_nicecube.png',
          },
          {
            rarity: 'Epic',
            filename: '03_0003s_0007s_0001_epic_nicecube.png',
          },
          {
            rarity: 'Legendary',
            filename: '03_0003s_0007s_0000_legendary_nicecube.png',
          },
          {
            rarity: 'Rare',
            filename: '03_0003s_0007s_0002_rare_nicecube.png',
          },
        ],
      },
      {
        name: 'Gangimal (Horck) Plushie',
        rarity: 'Epic',
        filename: 'horckplushie',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '03_0003s_0004s_0003_common_horckplushie.png',
          },
          {
            rarity: 'Epic',
            filename: '03_0003s_0004s_0001_epic_horckplushie.png',
          },
          {
            rarity: 'Legendary',
            filename: '03_0003s_0004s_0000_legendary_horckplushie.png',
          },
          {
            rarity: 'Rare',
            filename: '03_0003s_0004s_0002_rare_horckplushie.png',
          },
        ],
      },
      {
        name: 'CEO Of Comments Mug',
        set: 'CEO',
        rarity: 'Legendary',
        filename: 'ceomug',
        score: '4',
        colors: [
          {
            rarity: 'Common',
            filename: '03_0003s_0008s_0003_common_ceomug.png',
          },
          {
            rarity: 'Epic',
            filename: '03_0003s_0008s_0001_epic_ceomug.png',
          },
          {
            rarity: 'Legendary',
            filename: '03_0003s_0008s_0000_legendary_ceomug.png',
          },
          {
            rarity: 'Rare',
            filename: '03_0003s_0008s_0002_rare_ceomug.png',
          },
        ],
      },
      {
        name: 'Meataverse Portal Device',
        rarity: 'Legendary',
        filename: 'meataverseportaldevice',
        score: '4',
        colors: [
          {
            rarity: 'Common',
            filename: '03_0003s_0005s_0003_common_meataverseportaldevice.png',
          },
          {
            rarity: 'Epic',
            filename: '03_0003s_0005s_0001_epic_meataverseportaldevice.png',
          },
          {
            rarity: 'Legendary',
            filename:
              '03_0003s_0005s_0000_legendary_meataverseportaldevice.png',
          },
          {
            rarity: 'Rare',
            filename: '03_0003s_0005s_0002_rare_meataverseportaldevice.png',
          },
        ],
      },
    ],
  },
  {
    category: 'Legs',
    layerNo: 8,
    connectedLayerNo: -1,
    elements: [
      {
        name: 'Slim Jim Leggings',
        rarity: 'Common',
        filename: 'sjleggings',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '08_0008s_0000s_0003_common_sjleggings.png',
          },
          {
            rarity: 'Epic',
            filename: '08_0008s_0000s_0001_epic_sjleggings.png',
          },
          {
            rarity: 'Legendary',
            filename: '08_0008s_0000s_0000_legendary_sjleggings.png',
          },
          {
            rarity: 'Rare',
            filename: '08_0008s_0000s_0002_rare_sjleggings.png',
          },
        ],
      },
      {
        name: 'Meat Shorts',
        set: 'Meat',
        rarity: 'Common',
        filename: 'meatshots',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '08_0008s_0001s_0003_common_meatshots.png',
          },
          {
            rarity: 'Epic',
            filename: '08_0008s_0001s_0001_epic_meatshots.png',
          },
          {
            rarity: 'Legendary',
            filename: '08_0008s_0001s_0000_legendary_meatshots.png',
          },
          {
            rarity: 'Rare',
            filename: '08_0008s_0001s_0002_rare_meatshots.png',
          },
        ],
      },
      {
        name: 'Lab Pants',
        set: 'Lab',
        rarity: 'Common',
        filename: 'labpants',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '08_0008s_0003s_0003_common_labpants.png',
          },
          {
            rarity: 'Epic',
            filename: '08_0008s_0003s_0001_epic_labpants.png',
          },
          {
            rarity: 'Legendary',
            filename: '08_0008s_0003s_0000_legendary_labpants.png',
          },
          {
            rarity: 'Rare',
            filename: '08_0008s_0003s_0002_rare_labpants.png',
          },
        ],
      },
      {
        name: '69 Tattoo',
        rarity: 'Common',
        filename: '69tattoolegs',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '08_0008s_0005s_0003_common_69tattoolegs.png',
          },
          {
            rarity: 'Epic',
            filename: '08_0008s_0005s_0001_epic_69tattoolegs.png',
          },
          {
            rarity: 'Legendary',
            filename: '08_0008s_0005s_0000_legendary_69tattoolegs.png',
          },
          {
            rarity: 'Rare',
            filename: '08_0008s_0005s_0002_rare_69tattoolegs.png',
          },
        ],
      },
      {
        name: 'Slender James Tux Pants',
        set: 'Tuxedo',
        rarity: 'Legendary',
        filename: 'suitpants',
        score: '4',
        colors: [
          {
            rarity: 'Common',
            filename: '08_0008s_0004s_0003_common_suitpants.png',
          },
          {
            rarity: 'Epic',
            filename: '08_0008s_0004s_0001_epic_suitpants.png',
          },
          {
            rarity: 'Legendary',
            filename: '08_0008s_0004s_0000_legendary_suitpants.png',
          },
          {
            rarity: 'Rare',
            filename: '08_0008s_0004s_0002_rare_suitpants.png',
          },
        ],
      },
      {
        name: '"Eat Me" Slim Jim Overalls Bottom',
        set: 'Eat Me',
        rarity: 'Rare',
        filename: 'sjoverallslegs',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '08_0008s_0010s_0003_common_sjoverallslegs.png',
          },
          {
            rarity: 'Epic',
            filename: '08_0008s_0010s_0001_epic_sjoverallslegs.png',
          },
          {
            rarity: 'Legendary',
            filename: '08_0008s_0010s_0000_legendary_sjoverallslegs.png',
          },
          {
            rarity: 'Rare',
            filename: '08_0008s_0010s_0002_rare_sjoverallslegs.png',
          },
        ],
      },
      {
        name: 'Cyborg Legs',
        set: 'Cyborg',
        rarity: 'Rare',
        filename: 'cyborglegs',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '08_0008s_0011s_0003_common_cyborglegs.png',
          },
          {
            rarity: 'Epic',
            filename: '08_0008s_0011s_0001_epic_cyborglegs.png',
          },
          {
            rarity: 'Legendary',
            filename: '08_0008s_0011s_0000_legendary_cyborglegs.png',
          },
          {
            rarity: 'Rare',
            filename: '08_0008s_0011s_0002_rare_cyborglegs.png',
          },
        ],
      },
      {
        name: 'Cowboy Jeans',
        rarity: 'Rare',
        filename: 'cowboyjeans',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '08_0008s_0008s_0003_common_cowboyjeans.png',
          },
          {
            rarity: 'Epic',
            filename: '08_0008s_0008s_0001_epic_cowboyjeans.png',
          },
          {
            rarity: 'Legendary',
            filename: '08_0008s_0008s_0000_legendary_cowboyjeans.png',
          },
          {
            rarity: 'Rare',
            filename: '08_0008s_0008s_0002_rare_cowboyjeans.png',
          },
        ],
      },
      {
        name: 'Hairy Legs With Swimwear',
        rarity: 'Epic',
        filename: 'swimwear',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '08_0008s_0002s_0003_common_swimwear.png',
          },
          {
            rarity: 'Epic',
            filename: '08_0008s_0002s_0001_epic_swimwear.png',
          },
          {
            rarity: 'Legendary',
            filename: '08_0008s_0002s_0000_legendary_swimwear.png',
          },
          {
            rarity: 'Rare',
            filename: '08_0008s_0002s_0002_rare_swimwear.png',
          },
        ],
      },
      {
        name: 'SJ Kilt',
        rarity: 'Epic',
        filename: 'kilt',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '08_0008s_0009s_0003_common_kilt.png',
          },
          {
            rarity: 'Epic',
            filename: '08_0008s_0009s_0001_epic_kilt.png',
          },
          {
            rarity: 'Legendary',
            filename: '08_0008s_0009s_0000_legendary_kilt.png',
          },
          {
            rarity: 'Rare',
            filename: '08_0008s_0009s_0002_rare_kilt.png',
          },
        ],
      },
      {
        name: 'Wrestling Singlet Legs',
        rarity: 'Epic',
        filename: 'wrestlingsingletlegs',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '08_0008s_0007s_0003_common_wrestlingsingletlegs.png',
          },
          {
            rarity: 'Epic',
            filename: '08_0008s_0007s_0001_epic_wrestlingsingletlegs.png',
          },
          {
            rarity: 'Legendary',
            filename: '08_0008s_0007s_0000_legendary_wrestlingsingletlegs.png',
          },
          {
            rarity: 'Rare',
            filename: '08_0008s_0007s_0002_rare_wrestlingsingletlegs.png',
          },
        ],
      },
      {
        name: 'Slim Jim Bodysuit Legs',
        rarity: 'Common',
        filename: 'bodysuitlegs',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '08_0008s_0006s_0003_common_bodysuitlegs.png',
          },
          {
            rarity: 'Epic',
            filename: '08_0008s_0006s_0001_epic_bodysuitlegs.png',
          },
          {
            rarity: 'Legendary',
            filename: '08_0008s_0006s_0000_legendary_bodysuitlegs.png',
          },
          {
            rarity: 'Rare',
            filename: '08_0008s_0006s_0002_rare_bodysuitlegs.png',
          },
        ],
      },
      {
        name: 'Gold CEO',
        set: 'CEO',
        rarity: 'Legendary',
        filename: '08_0008s_0012s_0000_legendary_ceogoldlegs.png',
        score: '15',
      },
    ],
  },
  {
    category: 'Feet',
    layerNo: 7,
    connectedLayerNo: -1,
    elements: [
      {
        name: 'Cowboy Boots',
        rarity: 'Common',
        filename: 'cowboyboots',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '07_0007s_0006s_0003_common_cowboyboots.png',
          },
          {
            rarity: 'Epic',
            filename: '07_0007s_0006s_0001_epic_cowboyboots.png',
          },
          {
            rarity: 'Legendary',
            filename: '07_0007s_0006s_0000_legendary_cowboyboots.png',
          },
          {
            rarity: 'Rare',
            filename: '07_0007s_0006s_0002_rare_cowboyboots.png',
          },
        ],
      },
      {
        name: 'Slim Jim Box Shoes',
        rarity: 'Common',
        filename: 'sjpack',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '07_0007s_0008s_0003_common_sjpack.png',
          },
          {
            rarity: 'Epic',
            filename: '07_0007s_0008s_0001_epic_sjpack.png',
          },
          {
            rarity: 'Legendary',
            filename: '07_0007s_0008s_0000_legendary_sjpack.png',
          },
          {
            rarity: 'Rare',
            filename: '07_0007s_0008s_0002_rare_sjpack.png',
          },
        ],
      },
      {
        name: 'Meaty Low Sneakers',
        rarity: 'Common',
        filename: 'meatydunks',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '07_0007s_0005s_0003_common_meatydunks.png',
          },
          {
            rarity: 'Epic',
            filename: '07_0007s_0005s_0001_epic_meatydunks.png',
          },
          {
            rarity: 'Legendary',
            filename: '07_0007s_0005s_0000_legendary_meatydunks.png',
          },
          {
            rarity: 'Rare',
            filename: '07_0007s_0005s_0002_rare_meatydunks.png',
          },
        ],
      },
      {
        name: 'Hot Sneaks',
        rarity: 'Common',
        filename: 'meatforceone',
        score: '1',
        colors: [
          {
            rarity: 'Common',
            filename: '07_0007s_0009s_0003_common_meatforceone.png',
          },
          {
            rarity: 'Epic',
            filename: '07_0007s_0009s_0001_epic_meatforceone.png',
          },
          {
            rarity: 'Legendary',
            filename: '07_0007s_0009s_0000_legendary_meatforceone.png',
          },
          {
            rarity: 'Rare',
            filename: '07_0007s_0009s_0002_rare_meatforceone.png',
          },
        ],
      },
      {
        name: 'Cyborg Feet',
        set: 'Cyborg',
        rarity: 'Rare',
        filename: 'cyborgfeet',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '07_0007s_0000s_0003_common_cyborgfeet.png',
          },
          {
            rarity: 'Epic',
            filename: '07_0007s_0000s_0001_epic_cyborgfeet.png',
          },
          {
            rarity: 'Legendary',
            filename: '07_0007s_0000s_0000_legendary_cyborgfeet.png',
          },
          {
            rarity: 'Rare',
            filename: '07_0007s_0000s_0002_rare_cyborgfeet.png',
          },
        ],
      },
      {
        name: 'On Fire Sneakers',
        rarity: 'Rare',
        filename: 'sneakersonfire',
        score: '2',
        colors: [
          {
            rarity: 'Common',
            filename: '07_0007s_0002s_0003_common_sneakersonfire.png',
          },
          {
            rarity: 'Epic',
            filename: '07_0007s_0002s_0001_epic_sneakersonfire.png',
          },
          {
            rarity: 'Legendary',
            filename: '07_0007s_0002s_0000_legendary_sneakersonfire.png',
          },
          {
            rarity: 'Rare',
            filename: '07_0007s_0002s_0002_rare_sneakersonfire.png',
          },
        ],
      },
      {
        name: 'Roller Blade',
        rarity: 'Epic',
        filename: 'rollerblades',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '07_0007s_0003s_0003_common_rollerblades.png',
          },
          {
            rarity: 'Epic',
            filename: '07_0007s_0003s_0001_epic_rollerblades.png',
          },
          {
            rarity: 'Legendary',
            filename: '07_0007s_0003s_0000_legendary_rollerblades.png',
          },
          {
            rarity: 'Rare',
            filename: '07_0007s_0003s_0002_rare_rollerblades.png',
          },
        ],
      },
      {
        name: 'Ballet Slippers',
        set: 'Ballet',
        rarity: 'Epic',
        filename: 'balletshoes',
        score: '3',
        colors: [
          {
            rarity: 'Common',
            filename: '07_0007s_0001s_0003_common_balletshoes.png',
          },
          {
            rarity: 'Epic',
            filename: '07_0007s_0001s_0001_epic_balletshoes.png',
          },
          {
            rarity: 'Legendary',
            filename: '07_0007s_0001s_0000_legendary_balletshoes.png',
          },
          {
            rarity: 'Rare',
            filename: '07_0007s_0001s_0002_rare_balletshoes.png',
          },
        ],
      },
      {
        name: 'Rocket Boots',
        rarity: 'Legendary',
        filename: 'rocketboots',
        score: '4',
        colors: [
          {
            rarity: 'Common',
            filename: '07_0007s_0004s_0003_common_rocketboots.png',
          },
          {
            rarity: 'Epic',
            filename: '07_0007s_0004s_0001_epic_rocketboots.png',
          },
          {
            rarity: 'Legendary',
            filename: '07_0007s_0004s_0000_legendary_rocketboots.png',
          },
          {
            rarity: 'Rare',
            filename: '07_0007s_0004s_0002_rare_rocketboots.png',
          },
        ],
      },
      {
        name: 'Gold CEO Shoes',
        rarity: 'Legendary',
        filename: '07_0007s_0007s_0000_legendary_ceogoldfeet.png',
        score: '15',
      },
    ],
  },
  {
    category: 'Capsule',
    layerNo: 13,
    connectedLayerNo: -1,
    elements: [
      {
        name: 'Standard Capsule',
        rarity: 'Common',
        filename: '13_0013s_0000_common_standardc.png',
        score: '1',
      },
      {
        name: 'Black Plastic Capsule',
        rarity: 'Common',
        filename: '13_0013s_0001_common_blackplasticc.png',
        score: '1',
      },
      {
        name: 'White Plastic Capsule',
        rarity: 'Common',
        filename: '13_0013s_0002_common_whiteplasticc.png',
        score: '1',
      },
      {
        name: 'Shiny Black Capsule',
        rarity: 'Common',
        filename: '13_0013s_0003_common_shinyblackc.png',
        score: '1',
      },
      {
        name: 'Steel Capsule',
        rarity: 'Common',
        filename: '13_0013s_0004_common_steelc.png',
        score: '1',
      },
      {
        name: 'Rusty Capsule',
        rarity: 'Rare',
        filename: '13_0013s_0005_rare_rustycapsule.png',
        score: '2',
      },
      {
        name: 'Premium Standard Capsule',
        rarity: 'Rare',
        filename: '13_0013s_0006_rare_premiumstandardc.png',
        score: '2',
      },
      {
        name: 'Slim Jim Yellow Capsule',
        rarity: 'Epic',
        filename: '13_0013s_0008_epic_slimjimyellowc.png',
        score: '3',
      },
      {
        name: 'Slim Jim Red Capsule',
        rarity: 'Epic',
        filename: '13_0013s_0007_epic_slimjimredc.png',
        score: '3',
      },
      {
        name: 'Gold Capsule',
        rarity: 'Legendary',
        filename: '13_0013s_0009_legendary_goldc.png',
        score: '4',
      },
      {
        name: 'Iridescent Capsule',
        rarity: 'Legendary',
        filename: '13_0013s_0010_legendary_iridescentc.png',
        score: '4',
      },
    ],
  },
];
